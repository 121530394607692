/**
 * A simple FNV-1a hash, alternative to @sindresorhus/string-hash
 *
 * This is used primarily for getting gravatars (e.g. for docs contributors).
 *
 * @sindresorhus/string-hash relies on TextEncoder in order to support unicode
 * characters. Its use of globalThis.TextEncoder has caused some issues for us
 * in unit testing. Since we don't need to support unicode characters, we can
 * use this simpler implementation instead.
 *
 * @param str - The string to hash.
 * @returns A hash of the string.
 */
export function stringHash(str: string) {
  let h = offset;
  for (const char of str) {
    h ^= BigInt(char.charCodeAt(0));
    h = (h * prime) & 0xffffffffn;
  }
  return h.toString();
}

// Prime and offset for 32-bit FNV-1a hash
const prime = 16_777_619n;
const offset = 2_166_136_261n;
