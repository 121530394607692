export enum FeatureFlag {
  AI_ASSISTANT_STATIC_PROMPTS = 'ai_assistant_static_prompts',
  AI_CUSTOM_PROJECT = 'ai_custom_project',
  AI_FREE_RESPONSE_QUESTION = 'ai_free_response_question',
  BAM_SUMMARY_TAB = 'bam_summary_tab',
  BEHAVIORAL_INTERVIEW = 'behavioral_interview',
  BUSINESS_RAISE_INVOICE_OPTION = 'business_raise_invoice_option',
  COHORTS_INTERSTITIAL = 'cohorts_interstitial',
  DASHBOARD_SMART_PRACTICE = 'dashboard_smart_practice',
  ENTERPRISE_GLOBAL_NAV = 'enterprise_global_nav',
  FIDES_CONSENT_MANAGEMENT = 'fides_consent_management',
  GOLD_EARLY_ACCESS = 'gold_early_access',
  POST_QUIZ_REVIEW = 'ai_post_quiz_review',
  PRACTICE_EXPERIENCE = 'practice_xp',
  RELEASE_EMODULE_PAGES = 'release_emodule_pages',
  RESPONSIVE_LE = 'responsive_le',
  STRIPE_AFTERPAY_CHECKOUT = 'stripe_afterpay_checkout',
  WEEKLY_SYLLABUS = 'weekly_syllabus',
  XP_LEADERBOARD_INTERSTITIAL = 'xp_leaderboard_interstitial',
  STUDY_GROUP_ONBOARDING_OFFBOARDING = 'study_group_on_off_boarding',
  BUSINESS_TEAMS_RENEWAL = 'business_teams_renewal',
  CYCLE_G_TEAMS_ACCOUNT_SETUP = 'cycle_g_teams_account_setup',
  CYCLE_G_AI_PROJECT_REVIEW = 'cycle_g_ai_project_review',
}
